<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div v-if="!loadingAgency">
              <ValidationObserver
                v-if="agency"
                v-slot="{ handleSubmit, invalid, touched }"
                ref="updateForm"
              >
                <form
                  class="authentication-form"
                  @submit.prevent="handleSubmit(updateAgency)"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <TextInput
                        v-model="agency.owner.first_name"
                        name="first_name"
                        rules="required"
                        label="First Name"
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="agency.owner.last_name"
                        name="last_name"
                        rules="required"
                        label="Last Name"
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="agency.name"
                        name="company_name"
                        rules="required"
                        label="Company Name"
                      />
                    </div>
                    <div class="col-md-6">
                      <TextInput
                        v-model="agency.owner.email"
                        name="owner.email"
                        rules="required|email"
                        label="Email"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-6">
                          <TextInput
                            v-model="agency.subdomain"
                            name="subdomain"
                            rules="required|subdomain"
                            label="Landing Page"
                            linkstart="https://app.showmyrep.io/p/"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <TextInput
                          v-model="agency.owner.trial_ends_at"
                          type="date"
                          name="owner.trial_ends"
                          label="Trial Ends Date"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Subscription plans</label>
                        <table v-if="agency.active_plans.length" class="table">
                          <thead>
                            <tr>
                              <th>Subscription</th>
                              <th>Type</th>
                              <th>Status</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="plan in agency.active_plans" :key="plan.id">
                              <td>{{plan.plan.name}}</td>
                              <td>{{plan.product_type}}</td>
                              <td>
                                <span v-if="plan.past_due">Past Due</span>
                                <span v-else>Active</span>
                              </td>
                              <td>
                                <b-button
                                    v-if="plan.plan.type !== 'one_time'"
                                    variant="danger"
                                    :disabled="plan.is_deleting"
                                    size="sm"
                                    type="submit"
                                >
                                  <b-spinner v-if="plan.is_deleting" small />
                                  <span v-else @click="cancelSubscription(plan)">Cancel</span>
                                </b-button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div v-else>User does not have any subscribed plans</div>
                      </div>

                    </div>
                    <div class="col-md-6">
                      <div class="col-md-6 mb-4 mt-2">
                        <div class="mt-4 pt-2">
                          <span class="pr-2" style="font-weight:600;">OFF</span>
                          <b-form-checkbox
                            v-model="agency.free_fee"
                            name="check-button"
                            switch
                            inline
                          >
                            ON - Free fee
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                  </div>
                  <b-form-group>
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <b-button
                        variant="primary"
                        :disabled="loadingUpdate || (invalid && touched)"
                        type="submit"
                      >
                        <b-spinner v-if="loadingUpdate" small />
                        <span v-else>Update</span>
                      </b-button>

                      <b-button
                        variant="primary"
                        :disabled="loadingImpersonate"
                        @click="impersonate"
                      >
                        <b-spinner v-if="loadingImpersonate" small />
                        <span v-else
                          >Log in <i class="uil uil-sign-out-alt"></i
                        ></span>
                      </b-button>
                    </div>
                  </b-form-group>
                </form>
              </ValidationObserver>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver
              v-slot="{ handleSubmit, invalid, touched }"
              ref="passwordForm"
            >
              <form
                class="authentication-form"
                @submit.prevent="handleSubmit(changePassword)"
              >
                <div class="row">
                  <div class="col-md-6">
                    <TextInput
                      v-model="password.password"
                      name="password"
                      rules="required|confirmed:confirm_password"
                      label="Password"
                      type="password"
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      v-model="password.password_confirmation"
                      vid="confirm_password"
                      name="confirm_password"
                      rules="required"
                      label="Confirm Password"
                      type="password"
                    />
                  </div>
                </div>
                <b-form-group>
                  <b-button
                    variant="primary"
                    :disabled="loadingPassword || (invalid && touched)"
                    type="submit"
                  >
                    <b-spinner v-if="loadingPassword" small />
                    <span v-else>Change</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loadingAgency: false,
      agency: null,
      loadingUpdate: false,
      subscribed: false,
      loadingPassword: false,
      password: {
        password: '',
        password_confirmation: '',
      },
      loadingImpersonate: false,
    }
  },

  computed: {
    plans() {
      return this.$store.getters['subscription/allPlans'] || []
    },
  },

  mounted() {
    if (!this.$store.getters['subscription/allPlans']) {
      this.$store.dispatch('subscription/getAllPlans')
    }

    this.getAgency()
  },

  methods: {
    updateAgency() {
      this.loadingUpdate = true

      this.$store
        .dispatch('agency/update', this.agency)
        .then((agency) => {
          this.agency = agency
          this.loadingUpdate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },

    getAgency() {
      this.loadingAgency = true

      this.$store
        .dispatch('agency/find', this.$route.params.id)
        .then((agency) => {
          this.subscribed = agency.stripe_plan !== null
          this.agency = agency
          this.loadingAgency = false
        })
        .catch(() => {
          this.$router.push({ name: 'admin.agencies.index' })
          this.loadingAgency = false
        })
    },

    cancelSubscription(plan) {
      plan.is_deleting = true

      this.$store
        .dispatch('subscription/cancel', {
          user_id:this.agency.owner.id,
          plan_id:plan.plans_id
        })
        .then((agency) => {
          plan.is_deleting = false
          this.getAgency();
        })
        .catch(() => {
          plan.is_deleting = false
        })
    },

    changePassword() {
      this.loadingPassword = true

      this.$store
        .dispatch('user/changePassword', {
          ...this.password,
          user_id: this.agency.owner.id,
        })
        .then(() => {
          this.password = {}
          this.loadingPassword = false
          this.$refs.passwordForm.reset()
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.passwordForm.setErrors(err.response.data.errors)
          }
          this.loadingPassword = false
        })
    },

    impersonate(userId) {
      this.loadingImpersonate = true
      const user = this.agency.owner
      if (user) {
        this.$store
          .dispatch('auth/impersonate', user.id)
          .then((res) => {
            this.$store
              .dispatch('auth/getProfile')
              .then((user) => {
                this.$router.push('/' + user.role.toLocaleLowerCase())
                this.loadingImpersonate = false
              })
              .catch(() => {
                this.loadingImpersonate = false
              })
          })
          .catch(() => {
            this.loadingImpersonate = false
          })
      }
    },
  },
}
</script>
